<style lang="scss" scoped>
.register {
	text-align: center;
	padding: 0 4vw;
}
.logo {
	margin-top: 15vh;
	width: 70vw;
	margin-bottom: 30px;
}
.btn {
	margin-top: 40px;
}
.tel {
	font-size: 14px;
	font-weight: bold;
	position: absolute;
	left: 0;
	bottom: 4vh;
	text-align: center;
	width: 100%;
	z-index: -1;
}
.send_msg_btn {
	color: #fff;
}
::v-deep .van-field__left-icon {
	display: flex;
	align-items: center;
}
</style>

<template>
	<div class="register">
		<img :src="logo" alt="logo" class="logo" />
		<van-form ref="form" @submit="submit" input-align="center" error-message-align="right">
			<!-- 姓名 -->
			<van-field name="name" v-model="name" :rules="rules.name" placeholder="请输入姓名">
				<template #left-icon>
					<van-icon class="iconfont ybx_name" />
				</template>
			</van-field>

			<!-- 密码 -->
			<van-field name="password" v-model="password" :rules="rules.password" type="password" placeholder="请输入6-20位登录密码">
				<template #left-icon>
					<van-icon class="iconfont ybx_password" />
				</template>
			</van-field>

			<!-- 密码2 -->
			<van-field name="password2" v-model="password2" :rules="rules.password2" type="password" placeholder="请确认密码">
				<template #left-icon>
					<van-icon class="iconfont ybx_password" />
				</template>
			</van-field>

			<!-- 手机号码 -->
			<van-field v-model="mobile" size="large" name="mobile" type="number" left-icon="ybx_mobile" :rules="rules.mobile" placeholder="请输入手机号码">
				<template #left-icon>
					<van-icon class="iconfont ybx_mobile" />
				</template>
			</van-field>

			<!-- 图片验证码 -->
			<van-field name="imgCode" v-model="imgCode" :rules="rules.imgCode" placeholder="请输入图片验证码">
				<template #left-icon>
					<van-icon class="iconfont ybx_pic" />
				</template>
				<template #extra>
					<van-image :src="txmImg" width="80px" @click="changeImg" fit="scale-down">
						<template #loading>
							<van-loading type="spinner" size="20" />
						</template>
					</van-image>
				</template>
			</van-field>

			<!-- 短信验证码 -->
			<van-field name="msgCode" v-model="msgCode" :rules="rules.msgCode" placeholder="请输入短信验证码">
				<template #left-icon>
					<van-icon class="iconfont ybx_msg" />
				</template>
				<template #button>
					<van-button size="small" type="info" @click.stop="sendMsg" :disabled="waitMsg" :loading="msgLoading">
						<span v-show="!waitMsg">获取验证码</span>
						<van-count-down v-show="waitMsg" ref="countDown" class="time" :time="60000" @finish="finish">
							<template #default="timeData">
								<span class="send_msg_btn">{{ timeData.seconds }}秒</span>
							</template>
						</van-count-down>
					</van-button>
				</template>
			</van-field>

			<!-- 注册按钮 -->
			<div class="btn">
				<van-button type="info" block native-type="submit" :loading="loginLoading">注册</van-button>
			</div>
			<div class="tel">
				报案电话：400-660-9195
				<page-footer></page-footer>
			</div>
		</van-form>
	</div>
</template>

<script>
import { Field, DatetimePicker, Form, Icon, Button, CountDown, Image as VanImage, Loading, Toast } from 'vant';
import regular from '@/assets/js/regular';
import { logoMap } from '@/config/sundryParams';
import { login } from '@/request/billSearch';
import { http_getTemplateList, http_addDepAccount, http_getDict } from '@/request/common';
import { http_getMsgCode } from '@/request/proRegister.js';

export default {
	name: 'register', // 注册
	components: {
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Form.name]: Form,
		[Icon.name]: Icon,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[VanImage.name]: VanImage,
		[Loading.name]: Loading,
	},
	data() {
		return {
			pid: '',
			logo: '',
			defDepTemplateId: '',

			name: '', // 姓名
			password: '', // 密码
			password2: '', // 密码
			mobile: '', // 手机号码
			imgCode: '', // 图形验证码
			msgCode: '', // 短信验证码
			key: '',
			txmImg: '',

			rules: {
				name: [{ required: true, message: '' }],
				password: [
					{ required: true, message: '' },
					{ validator: this.pwdCheck, message: '请输入6-20位长度的密码' },
				],
				password2: [
					{ required: true, message: '' },
					{ validator: this.passwordCheck, message: '两次密码输入不同', trigger: 'onChange' },
				],
				mobile: [
					{ required: true, message: '' },
					{ pattern: regular.phone, message: '请输入正确的手机号码' },
				],
				password: [{ required: true, message: '' }],
				imgCode: [{ required: true }],
				msgCode: [{ required: true }],
			},
			msgLoading: false,
			waitMsg: false,
			loginLoading: false,
		};
	},
	created() {
		this.pid = this.$route.query.pid;
		this.getLogoInfo();
		this.getDefTemplate();
		this.changeImg();
	},
	methods: {
		// 获取logo配置信息，并设置logo
		getLogoInfo() {
			http_getDict('h5OffLineLogo').then(res => {
				let logoInfo = res.filter(item => item.code === this.pid);
				if (logoInfo.length) {
					let key = logoInfo[0].value;
					this.logo = Object.hasOwnProperty.call(logoMap, key) ? logoMap[key] : logoMap.default;
				}
			});
		},

		// 获取默认模板
		getDefTemplate() {
			http_getTemplateList(this.pid).then(res => {
				if (res.defDepTemplateId) {
					this.defDepTemplateId = res.defDepTemplateId;
				}
			});
		},

		submit(values) {
			let send = {
				mobile: values.mobile,
				name: values.name,
				contacts: values.name,
				password: values.password,
				tel: values.mobile,
				verifyCode: values.msgCode,
				templateId: this.defDepTemplateId,
			};
			http_addDepAccount(this.pid, send).then(res => {
				Toast.success('注册成功');
				setTimeout(() => {
					this.$router.push({
						path: 'login',
						query: { pid: this.pid },
					});
				}, 2000);
			});
		},

		// 获取验证码
		getMsgCode() {
			let send = {
				code: this.imgCode,
				key: this.key,
				mobile: this.mobile,
				type: 'REGIST',
			};
			return http_getMsgCode(send);
		},

		//改变图片验证码
		changeImg() {
			let time = new Date().getTime();
			this.key = time;
			this.txmImg = '/insurance/api/common/verifyCode/' + time;
		},

		finish() {
			this.waitMsg = false;
		},

		sendMsg() {
			this.$refs.form.validate(['mobile', 'imgCode']).then(() => {
				this.msgLoading = true;
				this.getMsgCode()
					.then(res => {
						if (this.$refs.countDown) {
							this.$refs.countDown.reset();
						}
						this.waitMsg = true;
						if (!Object.hasOwnProperty.call(res, 'code')) {
							Toast.success('获取成功');
						}
					})
					.finally(() => {
						this.msgLoading = false;
					});
			});
		},

		pwdCheck(val) {
			if (val) {
				return val.length >= 6 && val.length <= 20;
			} else {
				return true;
			}
		},

		passwordCheck(val) {
			return val === this.password;
		},
	},
};
</script>
